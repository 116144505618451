import "./styles.scss";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import { BsDatabase } from "react-icons/bs";
import { TbCategory } from "react-icons/tb";
import { LuUserCog2 } from "react-icons/lu";
import { isLoggedOut } from "../../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
function SideBar() {
  const dispatch = useDispatch();
  return (
    <div className="AuthSideBar">
      <div className="AuthSideBar-item">
        <BsDatabase size={22} />
      </div>
      <div className="AuthSideBar-item">
        <TbCategory size={22} />
      </div>
      <div className="AuthSideBar-item">
        <LuUserCog2 size={22} />
      </div>
      <div style={{ flex: 1 }} />
      <div className="AuthSideBar-item">
        <IoSettingsOutline size={25} />
      </div>
      <div className="AuthSideBar-item">
        <IoIosLogOut
          size={25}
          color="red"
          onClick={() => dispatch(isLoggedOut())}
        />
      </div>
    </div>
  );
}

export default SideBar;
