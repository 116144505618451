import { message, Table } from "antd";
import { BsCopy } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import "../styles.scss";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
function DataTable(props: any) {
  const navigate = useNavigate();
  const data = props?.data;

  const detailScreen = (id: any) => {
    const encodedId = btoa(id);
    navigate(`/auth/SubModelDetails/${encodedId}`);
  };
  const columns = [
    {
      title: "Identification",
      key: "identification",
      width: 600,
      render: (text: any) => {
        return (
          <div
            className="digitalTwinScreen-col2"
            onClick={() => detailScreen(text?.id)}
          >
            <div className="digitalTwinScreen-text6">{text?.idShort}</div>
            <div className="digitalTwinScreen-text7">{text?.id}</div>
          </div>
        );
      },
    },
    {
      title: "Semantic ID",

      key: "identification",
      // width: 280,
      render: (semanticId: any) => {
        return (
          <div
            className="subModalScreen-Semantic-text"
            onClick={() => detailScreen(semanticId?.id)}
          >
            {semanticId?.semanticId?.keys.map((key: any, index: number) => (
              <div key={index}>{key.value}</div>
            ))}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "arrow",
      width: 30,
      render: (text: any) => (
        <div onClick={() => detailScreen(text?.id)}>
          <IoIosArrowForward size={20} />
        </div>
      ),
    },
  ];

  const paginationConfig = {
    pageSize: 10,
    // showSizeChanger: true,
    // showQuickJumper: true,
    itemRender: (current: any, type: string, originalElement: any) => {
      if (type === "prev") {
        return (
          <LeftOutlined
            style={{
              color: props?.previousDisable ? "grey" : "inherit",
              cursor: props?.previousDisable ? "not-allowed" : "pointer",
            }}
            disabled={props?.previousDisable}
            onClick={() => {
              if (!props?.previousDisable) {
                props?.prev();
              }
            }}
          />
        );
      }
      if (type === "next") {
        return (
          <RightOutlined
            style={{
              color: props?.nextDisable ? "grey" : "inherit",
              cursor: props?.nextDisable ? "not-allowed" : "pointer",
            }}
            disabled={props?.nextDisable}
            onClick={() => {
              if (!props?.nextDisable) {
                props?.next();
              }
            }}
          />
        );
      }
      if (type === "page") {
        const displayPage = props?.page + 1;
        return (
          <span
            style={{ color: current === originalElement ? "blue" : "black" }}
          >
            {displayPage}
          </span>
        );
      }
      return originalElement;
    },
  };
  return (
    <div>
      <Table
        size="small"
        dataSource={data}
        columns={columns}
        loading={props?.loading}
        pagination={paginationConfig}
      />
    </div>
  );
}

export default DataTable;
