import { Table, Image } from "antd";
import { LuShip } from "react-icons/lu";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
function DataTable(props: any) {
  const navigate = useNavigate();

  const detailScreen = (id: any) => {
    const encodedId = btoa(id);
    navigate(`/auth/DgTwinDetails/${encodedId}`);
  };

  const columns = [
    {
      title: "Logo",
      dataIndex: "assetInformation",
      key: "id",
      width: 50,
      render: (text: any) => (
        <div className="digitalTwinScreen-col1" style={{ marginLeft: 7, marginTop:7,marginBottom:7 }}>
          {text?.defaultThumbnail?.path ? (
            <Image src={text?.defaultThumbnail?.path} />
          ) : (
            <LuShip />
          )}
        </div>
      ),
    },
    {
      title: "Identification",
      key: "identification",
      width: "100%",
      render: (text: any) => (
        <div
          className="digitalTwinScreen-col2"
          onClick={() => detailScreen(text?.id)}
        >
          <div className="digitalTwinScreen-text6">{text?.idShort}</div>
          <div className="digitalTwinScreen-text5">{text?.id}</div>
        </div>
      ),
    },
    {
      title: "",
      key: "arrow",
      width: 30,
      render: (text: any) => (
        <div
          className="digitalTwinScreen-col3"
          onClick={() => detailScreen(text?.id)}
        >
          <IoIosArrowForward size={20} />
        </div>
      ),
    },
  ];

  const paginationConfig = {
    pageSize: 10,
    // showSizeChanger: true,
    // showQuickJumper: true,
    itemRender: (current: any, type: string, originalElement: any) => {
      if (type === "prev") {
        return (
          <LeftOutlined
            style={{
              color: props?.previousDisable ? "grey" : "inherit",
              cursor: props?.previousDisable ? "not-allowed" : "pointer",
            }}
            disabled={props?.previousDisable}
            onClick={() => {
              if (!props?.previousDisable) {
                props?.prev();
              }
            }}
          />
        );
      }
      if (type === "next") {
        return (
          <RightOutlined
            style={{
              color: props?.nextDisable ? "grey" : "inherit",
              cursor: props?.nextDisable ? "not-allowed" : "pointer",
            }}
            disabled={props?.nextDisable}
            onClick={() => {
              if (!props?.nextDisable) {
                props?.next();
              }
            }}
          />
        );
      }
      if (type === "page") {
        const displayPage = props?.page + 1;
        return (
          <span
            style={{ color: current === originalElement ? "blue" : "black" }}
          >
            {displayPage}
          </span>
        );
      }
      return originalElement;
    },
  };
  return (
    <div>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        loading={props?.loading}
        pagination={paginationConfig}
      />
    </div>
  );
}

export default DataTable;
