import "./styles.scss";
import { Button, DatePicker, Input, Select } from "antd";
import { IoRefreshOutline } from "react-icons/io5";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import PageHeader from "../../components/pageHeader";
import DataTable from "./components/dataTable";
import { useEffect, useState } from "react";
import { GET } from "../../../utils/apiCall";
import API from "../../../config/api";

function SubModelScreen() {
  const navigate = useNavigate();
  const [subModelsData, setSubModelsData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [submodelCount, setSubmodelCount] = useState<number>(0);
  const [paginationArray, setPaginationArray] = useState<string[]>([""]);
  const [page, setPage] = useState<number>(0);
  const [previousDisable, setPreviousDisable] = useState<boolean>(
    page === 0 ? false : true
  );
  const [nextDisable, setNextDisable] = useState<boolean>(false);

  useEffect(() => {
    fetchData(page);
    fetchSubmodelCount();
  }, [search, page, paginationArray]);

  const fetchData = async (page: number) => {
    try {
      setLoading(true);
      const encodedSearch = btoa(search);

      let query = search
        ? `/${encodedSearch}`
        : `?order=DESC&idShort=&cursor=${paginationArray[page]}&limit=${10}`;
      const url = `${API.SUBMODAL_URL}${API.GET_SUBMODELS}${query}`;
      const response: any = await GET(url);
      if (response) {
        if (Array.isArray(response?.result)) {
          if (response?.result?.length) {
            setSubModelsData(response?.result);
            if (paginationArray?.length >= page) {
              if (response?.paging_metadata?.cursor) {
                setPaginationArray((prevPaginationArray) => {
                  if (prevPaginationArray.length >= page) {
                    const newCursor = response?.paging_metadata?.cursor;
                    if (!prevPaginationArray.includes(newCursor)) {
                      return [...prevPaginationArray, newCursor];
                    }
                  }
                  return prevPaginationArray;
                });
                setNextDisable(false);
              } else {
                setNextDisable(true);
              }
            }
            if (page === 0) {
              setPreviousDisable(true);
            } else {
              setPreviousDisable(false);
            }
          } else {
            setSubModelsData([]);
          }
        } else {
          if (response?.id) {
            setSubModelsData([response]);
          } else {
            setSubModelsData([]);
          }
        }
      } else {
        setSubModelsData([]);
      }
    } catch (error: any) {
      setSubModelsData([]);
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchSubmodelCount = async () => {
    try {
      const url = `${API.SUBMODAL_URL}${API.GET_SUBMODELS}/count`;
      const response: any = await GET(url);
      if (response) {
        setSubmodelCount(response);
      } else {
      }
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleReset = () => {
    setSearch("");
    fetchData(page);
  };

  return (
    <main>
      <Container fluid>
        <PageHeader
          title={`Submodels ( ${submodelCount} )`}
          breadcum={"Dashboard / Submodels"}
        >
          <div>
            <Input
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          <div>
            <Button onClick={() => setSearch("")}>
              <IoRefreshOutline size={17} onClick={handleReset} />
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => navigate(`/auth/SubModelScreenFrom/${btoa("0")}`)}
            >
              New
            </Button>
          </div>
        </PageHeader>
        <DataTable
          data={subModelsData}
          loading={loading}
          next={() => {
            setPage(page + 1);
          }}
          prev={() => {
            setPage(page - 1);
          }}
          nextDisable={nextDisable}
          previousDisable={previousDisable}
          page={page}
        />
      </Container>
    </main>
  );
}

export default SubModelScreen;
