import React, { ReactNode } from "react";
import "../styles.scss";

interface Props {
  title: string;
  icon2: ReactNode;
  description: string;
  color: string;
  alternative: String;
  navigate: string;
}
function Card3({ title, icon2, color, navigate, alternative }: Props) {
  return (
    <div className="dashboard-card1-cardHead">
      <div className="dashboard-card2-cardBox">
        <div className="dashboard-card1-title">{title}</div>
        <div className="dashboard-card1-iconHead">{icon2}</div>
      </div>
      <div className="subFooterCardTxt">{alternative}</div>
    </div>
  );
}

export default Card3;
