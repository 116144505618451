import "./styles.scss";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Popover,
  Spin,
} from "antd";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import PageHeader from "../../components/pageHeader";
import { GET, POST, PUT } from "../../../utils/apiCall";
import API from "../../../config/api";

import Property from "./elements/property";
import MultiLanguageProperty from "./elements/multiLanguageProperty";
import File from "./elements/file";
import Range from "./elements/range";
import ReferenceElement from "./elements/referenceElement";
import RelationshipElement from "./elements/relationshipElement";
import AnnotatedRelationshipElement from "./elements/annotatedRelationshipElement";
import Entity from "./elements/entity";
import { IoIosAdd, IoMdAdd } from "react-icons/io";

import {
  transformProperty,
  transformMultiLanguageProperty,
  transformFileProperty,
  transformRangeProperty,
  transformReferenceElement,
  transformRelationshipElement,
  transformSubmodelElementCollection,
} from "./elements/objects";
import SubmodelElementCollection from "./elements/submodelElementCollection";
import DisplayNameModal from "./components/displayNameModal";
import DescriptionModal from "./components/discriptionModal";

const componentMapping: any = {
  Property: Property,
  MultiLanguageProperty: MultiLanguageProperty,
  File: File,
  Range: Range,
  ReferenceElement: ReferenceElement,
  RelationshipElement: RelationshipElement,
  SubModelElementCollection: SubmodelElementCollection,
  // AnnotatedRelationshipElement: AnnotatedRelationshipElement,
  // Entity: Entity,
};

interface SubModelElement {
  type: keyof typeof componentMapping;
  key: number;
  fieldKey: number;
}

function SubModelScreenFrom() {
  let mappedSubmodelElementCollection:any
  const { id }: any = useParams();
  const decodedId: any = atob(id);
  const [form] = Form.useForm();
  // const formWatchData = Form.useWatch((values) => values, {
  //   form: form,
  //   preserve: true,
  // });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);
  const [languageCount, setLanguageCount] = useState<number>(1);
  const [referenceCount, setReferenceCount] = useState<number>(1);
  const [relationCount, setRelationCount] = useState<number>(1);
  const [subModelElements, setSubModelElements] = useState<SubModelElement[]>(
    []
  );
  const [descriptionCount, setDescriptionCount] = useState<number>(0);
  const [displayNameModal, setDisplayNameModal] = useState<boolean>(false);
  const [displayNameCount, setDisplayNameCount] = useState<number>(0);
  const [descriptionModal, setDescriptionModal] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [subModelElementsCollection, setSubModelElementsCollection] = useState<
    SubModelElement[]
  >([]);
  const [subModelElementCollection, setSubmodelElementCollection] =
    useState<boolean>(false);

  useEffect(() => {
    if (decodedId !== "0") {
      setType("edit");
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const encodedSearch = id;
      const url = `${API.SUBMODAL_URL}${API.GET_SUBMODELS}/${encodedSearch}`;
      const response: any = await GET(url);
      if (response?.id) {
        populateForm(response);
      }
    } catch (error: any) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const transformElements = (data: any) => {
    if (data?.modelType === "SubmodelElementCollection") {
      // setSubmodelElementCollection(true);
      return (
        data?.value?.map((subElement: any, index: number) => {
          switch (subElement?.modelType) {
            case "Property":
              return {
                ...transformSubmodels(subElement),
                value: subElement?.value,
              };
            case "MultiLanguageProperty":
              setLanguageCount(subElement?.value?.length);
              return {
                ...transformSubmodels(subElement),
                multilanguage_property: subElement?.value?.map(
                  (item: any) => item?.language
                ),
                language_description: subElement?.value?.map(
                  (item: any) => item?.text
                ),
              };
            case "File":
              return {
                ...transformSubmodels(subElement),
                value: subElement?.value,
                content_type: subElement?.contentType,
              };
            case "Range":
              return {
                ...transformSubmodels(subElement),
                valueType: subElement?.valueType,
                min: subElement?.min,
                max: subElement?.max,
              };
            case "ReferenceElement":
              setReferenceCount(subElement?.value?.keys?.length);
              return {
                ...transformSubmodels(subElement),
                value: subElement?.value?.keys?.map((item: any) => item?.value),
              };
            case "RelationshipElement":
              setRelationCount(subElement?.first?.keys?.length);
              return {
                ...transformSubmodels(subElement),
                first: subElement?.first?.keys?.map((item: any) => item?.value),
                second: subElement?.second?.keys?.map(
                  (item: any) => item?.value
                ),
              };
            case "SubmodelElementCollection":
              return {
                ...transformSubmodels(subElement),
                SubmodelElementCollection: [[...transformElements(subElement)]],
              };
            default:
              return null;
          }
        }) || []
      );
    } else {
      return (
        data?.submodelElements?.map((subElement: any, index: number) => {
          switch (subElement?.modelType) {
            case "Property":
              return {
                ...transformSubmodels(subElement),
                value: subElement?.value,
              };
            case "MultiLanguageProperty":
              setLanguageCount(subElement?.value?.length);
              return {
                ...transformSubmodels(subElement),
                multilanguage_property: subElement?.value?.map(
                  (item: any) => item?.language
                ),
                language_description: subElement?.value?.map(
                  (item: any) => item?.text
                ),
              };
            case "File":
              return {
                ...transformSubmodels(subElement),
                value: subElement?.value,
                content_type: subElement?.contentType,
              };
            case "Range":
              return {
                ...transformSubmodels(subElement),
                valueType: subElement?.valueType,
                min: subElement?.min,
                max: subElement?.max,
              };
            case "ReferenceElement":
              setReferenceCount(subElement?.value?.keys?.length);
              return {
                ...transformSubmodels(subElement),
                value: subElement?.value?.keys?.map((item: any) => item?.value),
              };
            case "RelationshipElement":
              setRelationCount(subElement?.first?.keys?.length);
              return {
                ...transformSubmodels(subElement),
                first: subElement?.first?.keys?.map((item: any) => item?.value),
                second: subElement?.second?.keys?.map(
                  (item: any) => item?.value
                ),
              };
            case "SubmodelElementCollection":
              return {
                ...transformSubmodels(subElement),
                SubmodelElementCollection: [[...transformElements(subElement)]],
              };
            default:
              return null;
          }
        }) || []
      );
    }
  };

  const populateForm = (data: any) => {
    let transformedElements;
    let transformedSubmodelElements;
    let displayNameData = [{}];
    let descriptionData = [{}];
    if (data?.displayName?.length) {
      displayNameData = data?.displayName?.map((item: any) => ({
        language: item?.language,
        display_name: item?.text,
      }));
    }
    if (data?.description?.length) {
      descriptionData = data?.description?.map((item: any) => ({
        language: item?.language,
        description: item?.text,
      }));
    }
    data?.submodelElements?.map((subElement: any, index: number) => {
      // if (subElement?.modelType === "SubmodelElementCollection") {
      //   transformedSubmodelElements = transformElements(subElement);
      // form.setFieldsValue({
      //   ...(subElement?.idShort && { id_shortSC: subElement.idShort }),
      //   ...(subElement?.semanticId?.keys?.[0]?.value && {
      //     semantic_idSC: subElement.semanticId.keys[0].value,
      //   }),
      //   ...(subElement?.displayName?.[0]?.text && {
      //     display_nameSC: subElement.displayName[0].text,
      //   }),
      //   ...(subElement?.description?.[0]?.text && {
      //     descriptionSC: subElement.description[0].text,
      //   }),
      // });
      // } else {
      transformedElements = transformElements(data);
      // }
    });
    form.setFieldsValue({
      id: data?.id,
      ...(data?.idShort && { id_short: data.idShort }),
      ...(data?.semanticId?.keys?.[0]?.value && {
        semantic_id: data.semanticId.keys[0].value,
      }),
      display_name: displayNameData,
      description: descriptionData,
      Elements: transformedElements,
    });
    updateStates(data);
  };

  const mapSubElements = (subElement: any, index: number) => {
    if (subElement.submodelElements) {
      // Map the nested submodelElements
      return {
        type: subElement.modelType,
        key: Date.now() + index,
        fieldKey: index,
        // ...subElement,
        // submodelElements: subElement.submodelElements.map((nestedSubElement: any, nestedIndex: number) =>
        //   mapSubElements(nestedSubElement, nestedIndex)
        // ),
      };
    } else if (subElement.value && Array.isArray(subElement.value)) {
      // Map the value if it's an array
      return {
        type: subElement.modelType,
        key: Date.now() + index,
        fieldKey: index,
        // ...subElement,
        // value: subElement.value.map((nestedSubElement: any, nestedIndex: number) =>
        //   mapSubElements(nestedSubElement, nestedIndex)
        // ),
      };
    } else {
      // No submodelElements or value, just map the element itself
      return {
        type: subElement.modelType,
        key: Date.now() + index,
        fieldKey: index,
        // ...subElement,
      };
    }
  };

  const mapSubElementsRecursive = (elements: any[], parentIndex = 0): any[] => {
    return elements.map((subElement: any, index: number) => {
      const mappedElement = {
        type: subElement.modelType,
        key: Date.now() + parentIndex + index,
        fieldKey: index,
      };
  
     
      if (subElement.submodelElements) {
        return {
          ...mappedElement,
          submodelElements: mapSubElementsRecursive(subElement.submodelElements, index),
        };
      }
  
     
      if (subElement.value && Array.isArray(subElement.value)) {
        return {
          ...mappedElement,
          value: mapSubElementsRecursive(subElement.value, index),
        };
      }
  
      
      return mappedElement;
    });
  };

  const updateStates = (data: any) => {
    setSubModelElements(
      data.submodelElements.map((subElement: any, index: number) =>
        mapSubElements(subElement, index)
      )
    );
    const submodelElementCollection = data?.submodelElements?.find(
      (subElement: any) => subElement.modelType === "SubmodelElementCollection"
    );
    if (submodelElementCollection) {
       mappedSubmodelElementCollection = mapSubElementsRecursive(submodelElementCollection.value);
      setSubModelElementsCollection(mappedSubmodelElementCollection);
      localStorage.setItem('submodelElements', JSON.stringify(mappedSubmodelElementCollection));
    }
    
  };

  const transformSubmodels = (data: any) => {
    return {
      id_short: data?.idShort,
      display_name: data?.displayName?.length
        ? data?.displayName[0]?.text
        : null,
      semantic_id: data?.semanticId?.keys?.length
        ? data?.semanticId?.keys[0]?.value
        : null,
      description: data?.description?.length
        ? data?.description[0]?.text
        : null,
    };
  };

  const createSubmodel = async (values: any) => {
    if (loadingRef.current) return;
    try {
      loadingRef.current = true;
      let transformedElements;
      let displayNameData = [];
      let descriptionData = [];
      if (values?.display_name) {
        displayNameData = values?.display_name.map((item: any) => {
          return {
            language: item?.language,
            text: item?.display_name,
          };
        });
      }

      if (values?.description) {
        descriptionData = values?.description.map((item: any) => {
          return {
            language: item?.language,
            text: item?.description,
          };
        });
      }
      if (values?.Elements) {
        transformedElements = values.Elements.map(
          (element: any, index: number) => {
            const componentName = subModelElements[index]?.type;
            switch (componentName) {
              case "Property":
                return transformProperty(element);
              case "MultiLanguageProperty":
                return transformMultiLanguageProperty(element);
              case "File":
                return transformFileProperty(element);
              case "Range":
                return transformRangeProperty(element);
              case "ReferenceElement":
                return transformReferenceElement(element);
              case "RelationshipElement":
                return transformRelationshipElement(element);
              case "SubModelElementCollection":
                return transformSubmodelElementCollection(element);
              case "SubmodelElementCollection":
                return transformSubmodelElementCollection(element);
              default:
                return {};
            }
          }
        );
      } else {
        transformedElements = [];
      }

      const raw = {
        idShort: values?.id_short,
        id: values?.id,
        kind: "Instance",
        semanticId: {
          type: "ExternalReference",
          keys: [
            {
              type: "Submodel",
              value: values?.semantic_id,
            },
          ],
        },
        description: descriptionData,
        displayName: displayNameData,
        submodelElements: transformedElements,
        modelType: "Submodel-1",
      };
      let url = decodedId != "0" ? `/${id}` : "";
      let method = decodedId != "0" ? PUT : POST;
      // let url = API.SUBMODAL_URL + API.ADD_SUBMODELS;
      // const response: any = {};
      const response: any = await method(
        API.SUBMODAL_URL + API.ADD_SUBMODELS + url,
        raw
      );
      if (decodedId != "0") {
        message.success("Successfully Edited");
        form.resetFields();
        navigate(-1);
      } else {
        if (response?.id) {
          message.success("Successfully created");
          form.resetFields();
          navigate(-1);
        } else {
          message.error("Failed to create submodel");
        }
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to create submodel");
    } finally {
      loadingRef.current = false;
    }
  };

  const items = Object.keys(
    componentMapping
  ) as (keyof typeof componentMapping)[];

  const handlePopoverItemClick = (
    item: keyof typeof componentMapping,
    add: any,
    fields: any[],
    type: boolean
  ) => {
    // if (item == "SubModel Element Collection") {
    //   setSubmodelElementCollection(true);
    //   setTimeout(() => {
    //     const formElement = document.querySelector(".ant-form");
    //     if (formElement) {
    //       formElement.scrollIntoView({ behavior: "smooth", block: "end" });
    //     }
    //   }, 0);
    //   return;
    // }

    add();
    // if (type) {
    setSubModelElements((prevElements) => [
      ...prevElements,
      { type: item, key: Date.now(), fieldKey: fields.length },
    ]);
    // } else {
    //   setSubModelElementsCollection((prevElements) => [
    //     ...prevElements,
    //     { type: item, key: Date.now(), fieldKey: fields.length },
    //   ]);
    // }
    setTimeout(() => {
      const formElement = document.querySelector(".ant-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 0);
  };

  const handleModalClose = () => {
    setDisplayNameModal(false);
    setDescriptionModal(false);
  };
  return (
    <main>
      <Container fluid>
        <PageHeader
          title={"Master Data"}
          breadcum={"Home / Submodels / New "}
        ></PageHeader>
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={createSubmodel}
            initialValues={{ display_name: [{}], description: [{}] }}
            scrollToFirstError
          >
            <div style={{ paddingBottom: "10vh" }}>
              <Card title={"Identification Attributes"}>
                <Row>
                  <Col sm={6} xs={12}>
                    <Form.Item
                      label="ID"
                      name="id"
                      rules={[{ required: true, message: "Please enter ID" }]}
                    >
                      <Input size="large" placeholder="Enter ID" />
                    </Form.Item>
                    <Form.Item label="Semantic ID" name="semantic_id">
                      <Input size="large" placeholder="Enter Semantic ID" />
                    </Form.Item>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Form.Item label="ID Short" name="id_short">
                      <Input size="large" placeholder="Enter ID Short" />
                    </Form.Item>
                    {/* <Form.Item label="Display Name" name="display_name">
                      <Input size="large" placeholder="Enter Display Name" />
                    </Form.Item> */}
                  </Col>
                </Row>
                {/* <Form.Item label="Description" name="description">
                  <Input.TextArea
                    size="large"
                    placeholder="Enter Description"
                  />
                </Form.Item> */}
                <Row>
                  <Col md={6}>
                    <Button
                      size="large"
                      block
                      onClick={() => setDisplayNameModal(true)}
                      icon={<IoIosAdd />}
                      style={{ fontSize: 14 }}
                    >
                      {`Display Name${
                        displayNameCount > 0 ? ` (${displayNameCount})` : ""
                      }`}
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      size="large"
                      block
                      onClick={() => setDescriptionModal(true)}
                      icon={<IoIosAdd />}
                      style={{ fontSize: 14 }}
                    >
                      {`Description${
                        descriptionCount > 0 ? ` (${descriptionCount})` : ""
                      }`}
                    </Button>
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </Card>
              <br />
              <Form.List name={"display_name"}>
                {(fields, { add, remove }) => {
                  const values = form.getFieldValue("display_name") || [];
                  const populatedFields = fields.filter((_fields, index) => {
                    const value = values[index];
                    return value && value?.language && value?.display_name;
                  });
                  setDisplayNameCount(populatedFields.length);
                  return (
                    <DisplayNameModal
                      form={form}
                      fields={fields}
                      add={add}
                      remove={(name: any) => remove(name)}
                      visible={displayNameModal}
                      onClose={handleModalClose}
                    />
                  );
                }}
              </Form.List>
              <Form.List name={"description"}>
                {(fields, { add, remove }) => {
                  const values = form.getFieldValue("description") || [];
                  const populatedFields = fields.filter((_fields, index) => {
                    const value = values[index];
                    return value && value?.language && value?.description;
                  });
                  setDescriptionCount(populatedFields.length);
                  return (
                    <DescriptionModal
                      form={form}
                      fields={fields}
                      add={add}
                      remove={(name: any) => remove(name)}
                      visible={descriptionModal}
                      onClose={handleModalClose}
                    />
                  );
                }}
              </Form.List>
              <Form.List name="Elements">
                {(fields, { add, remove }) => (
                  <Card
                    title={"Submodel Elements"}
                    extra={
                      <div className="gap-3 d-flex">
                        <Popover
                          arrow={false}
                          placement={"bottom"}
                          content={items.map((item: any, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                handlePopoverItemClick(item, add, fields, true);
                              }}
                              className="subModelScreenFrom-item"
                            >
                              {item}
                            </div>
                          ))}
                          trigger="click"
                        >
                          <Button type="primary" ghost>
                            Add Submodel Element
                          </Button>
                        </Popover>
                      </div>
                    }
                  >
                    {fields.map((field, index) => {
                      const element = subModelElements.find(
                        (el) => el.fieldKey === field.name
                      );

                      if (!element) return null;
                      let Component;
                      if (element?.type === "SubmodelElementCollection") {
                        Component =
                          componentMapping["SubModelElementCollection"];
                      } else {
                        Component = componentMapping[element?.type];
                      }
                      return (
                        <Component
                          key={element.key}
                          fields={[field]}
                          languageCount={languageCount}
                          referenceCount={referenceCount}
                          relationCount={relationCount}
                          form={form}
                          type={type}
                          count={type === "edit" && 1}
                          submodelElementCollection = {type === 'edit' && mappedSubmodelElementCollection}
                          remove={(name: any) => {
                            remove(name);
                            setSubModelElements((prevElements) =>
                              prevElements.filter(
                                (el) => el.key !== element.key
                              )
                            );
                          }}
                        />
                      );
                    })}
                  </Card>
                )}
              </Form.List>
              {/* {subModelElementCollection ? (
                <Card
                  title={"Submodel Element Collection"}
                  extra={
                    <Button
                      size="small"
                      danger
                      onClick={() => setSubmodelElementCollection(false)}
                    >
                      Remove
                    </Button>
                  }
                >
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>ID Short</th>
                        <th>Display Name</th>
                        <th>Semantic ID</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ padding: 0 }}>
                          <Form.Item
                            noStyle
                            name={"id_shortSC"}
                            rules={[
                              {
                                required: true,
                                message: "Please enter ID Short",
                              },
                            ]}
                          >
                            <Input
                              bordered={false}
                              size="large"
                              placeholder="Enter ID Short"
                              style={{ padding: 10 }}
                            />
                          </Form.Item>
                        </td>
                        <td style={{ padding: 0 }}>
                          <Form.Item
                            noStyle
                            name={"display_nameSC"}
                            rules={[
                              {
                                required: true,
                                message: "Please enter Display Name",
                              },
                            ]}
                          >
                            <Input
                              bordered={false}
                              size="large"
                              placeholder="Enter name"
                              style={{ padding: 10 }}
                            />
                          </Form.Item>
                        </td>
                        <td style={{ padding: 0 }}>
                          <Form.Item
                            noStyle
                            name={"semantic_idSC"}
                            rules={[
                              {
                                required: true,
                                message: "Please enter Semantic ID",
                              },
                            ]}
                          >
                            <Input
                              bordered={false}
                              size="large"
                              placeholder="Enter Semantic ID"
                              style={{ padding: 10 }}
                            />
                          </Form.Item>
                        </td>
                        <td style={{ padding: 0 }}>
                          <Form.Item
                            noStyle
                            name={"descriptionSC"}
                            rules={[
                              {
                                required: true,
                                message: "Please enter description",
                              },
                            ]}
                          >
                            <Input
                              bordered={false}
                              size="large"
                              placeholder="Enter description"
                              style={{ padding: 10 }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Form.List name="SubmodelElementCollection">
                    {(fields, { add, remove }) => (
                      <Card
                        extra={
                          <div>
                            <Popover
                              arrow={false}
                              placement={"bottom"}
                              content={items.map((item: any, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    handlePopoverItemClick(
                                      item,
                                      add,
                                      fields,
                                      false
                                    );
                                  }}
                                  className="subModelScreenFrom-item"
                                >
                                  {item}
                                </div>
                              ))}
                              trigger="click"
                            >
                              <Button type="primary" ghost>
                                Add Values
                              </Button>
                            </Popover>
                          </div>
                        }
                      >
                        {fields.map((field, index) => {
                          const element = subModelElementsCollection.find(
                            (el) => el.fieldKey === field.name
                          );
                          if (!element) return null;
                          const Component = componentMapping[element.type];
                          return (
                            <Component
                              key={element.key}
                              fields={[field]}
                              languageCount={languageCount}
                              referenceCount={referenceCount}
                              relationCount={relationCount}
                              form={form}
                              remove={(name: any) => {
                                remove(name);
                                setSubModelElementsCollection((prevElements) =>
                                  prevElements.filter(
                                    (el) => el.key !== element.key
                                  )
                                );
                              }}
                            />
                          );
                        })}
                      </Card>
                    )}
                  </Form.List>
                </Card>
              ) : null} */}
            </div>

            <div className="Footer-ActionBox">
              <div style={{ flex: 1 }}></div>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingRef.current}
                  style={{ marginRight: 20 }}
                >
                  Save
                </Button>
                <Button danger type="text" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </Spin>
      </Container>
    </main>
  );
}

export default SubModelScreenFrom;
