import { Modal, Table, Checkbox, Input } from "antd";
import { useEffect, useState } from "react";
import API from "../../../../config/api";
import { GET } from "../../../../utils/apiCall";
import Search from "antd/es/transfer/search";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
const SubmodalModal = (props: any) => {
  const [selectedSubmodels, setSelectedSubmodels] = useState<any[]>([]);
  const [subModelsData, setSubModelsData] = useState<any>([]);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationArray, setPaginationArray] = useState<string[]>([""]);
  const [page, setPage] = useState<number>(0);
  const [previousDisable, setPreviousDisable] = useState<boolean>(
    page === 0 ? false : true
  );
  const [nextDisable, setNextDisable] = useState<boolean>(false);
  const { Search } = Input;
  useEffect(() => {
    getSubmodel();
    if (props.selectedSubmodels && props.selectedSubmodels.length) {
      setSelectedSubmodels(props.selectedSubmodels);
    }
  }, [props.selectedSubmodels,search,page,paginationArray]);

  const getSubmodel = async () => {
    try {
      setLoading(true);
      const encodedSearch = btoa(search);

      let query = search
        ? `/${encodedSearch}`
        : `?order=DESC&idShort=&cursor=${paginationArray[page]}&limit=${10}`;
      const url = `${API.SUBMODAL_URL}${API.GET_SUBMODELS}${query}`;
      const response: any = await GET(url);
      if (response) {
        if (Array.isArray(response?.result)) {
          if (response?.result?.length) {
            setSubModelsData(response?.result);
            if (paginationArray?.length >= page) {
              if (response?.paging_metadata?.cursor) {
                setPaginationArray((prevPaginationArray) => {
                  if (prevPaginationArray.length >= page) {
                    const newCursor = response?.paging_metadata?.cursor;
                    if (!prevPaginationArray.includes(newCursor)) {
                      return [...prevPaginationArray, newCursor];
                    }
                  }
                  return prevPaginationArray;
                });
                setNextDisable(false);
              } else {
                setNextDisable(true);
              }
            }
            if (page === 0) {
              setPreviousDisable(true);
            } else {
              setPreviousDisable(false);
            }
          } else {
            setSubModelsData([]);
          }
        } else {
          if (response?.id) {
            setSubModelsData([response]);
          } else {
            setSubModelsData([]);
          }
        }
      } else {
        setSubModelsData([]);
      }
    } catch (error: any) {
      setSubModelsData([]);
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 10,
      render: (text: any) => (
        <Checkbox
          checked={selectedSubmodels.some(
            (item: any) => item.keys[0]?.value === text.id
          )}
          onChange={(e) => handleCheckboxChange(e, text)}
        />
      ),
    },
    {
      title: "Identification",
      render: (text: any) => (
        <div>
          <div className="subModalScreen-text1">{text?.idShort}</div>
          <div className="subModalScreen-text2">{text?.id}</div>
        </div>
      ),
    },
    {
      title: "Semantic ID",
      key: "semanticId",
      render: (text: any) => <div>{text?.semanticId?.keys[0]?.value}</div>,
    },
  ];

  const handleCheckboxChange = (e: any, data: any) => {
    if (e.target.checked) {
      let obj = {
        keys: [
          {
            type: data?.modelType,
            value: data?.id,
          },
        ],
        type: "ModelReference",
      };
      setSelectedSubmodels([...selectedSubmodels, obj]);
    } else {
      setSelectedSubmodels(
        selectedSubmodels.filter(
          (item: any) => item?.keys[0]?.value !== data.id
        )
      );
    }
  };

  const paginationConfig = {
    pageSize: 10,
    // showSizeChanger: true,
    // showQuickJumper: true,
    itemRender: (current: any, type: string, originalElement: any) => {
      if (type === "prev") {
        return (
          <LeftOutlined
            style={{
              color: previousDisable ? "grey" : "inherit",
              cursor: previousDisable ? "not-allowed" : "pointer",
            }}
            disabled={previousDisable}
            onClick={() => {
              if (!previousDisable) {
                setPage(page - 1);
              }
            }}
          />
        );
      }
      if (type === "next") {
        return (
          <RightOutlined
            style={{
              color: nextDisable ? "grey" : "inherit",
              cursor: nextDisable ? "not-allowed" : "pointer",
            }}
            disabled={nextDisable}
            onClick={() => {
              if (!nextDisable) {
                setPage(page + 1);
              }
            }}
          />
        );
      }
      if (type === "page") {
        const displayPage = page + 1;
        return (
          <span
            style={{ color: current === originalElement ? "blue" : "black" }}
          >
            {displayPage}
          </span>
        );
      }
      return originalElement;
    },
  };

  return (
    <Modal
      title="Submodel"
      open={props?.open}
      onOk={() => props?.saveSubmodels(selectedSubmodels)}
      onCancel={() => props?.close()}
      width={900}
      // style={{ top: 0 }}
    >
      <div className="d-flex justify-content-end pb-3">
        <Search
          placeholder="Search"
          onSearch={(e) => setSearch(e)}
          style={{ width: 200 }}
        />
      </div>
      <Table
        size="small"
        bordered
        dataSource={subModelsData}
        columns={columns}
        loading={loading}
        pagination={paginationConfig}
      />
    </Modal>
  );
};

export default SubmodalModal;
