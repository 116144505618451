import "./styles.scss";
import { Button, Form, Input, message } from "antd";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";
import Logo from "../../assets/images/logo.svg";
import { isLoggedIn } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";


function LoginScreen() {
  const navigate: any = useNavigate();
  const dispatch: any = useDispatch();

  const onFinish = (values: any) => {
    const credentials = `${values.userName}:${values.password}`;
      const c = btoa(credentials); // Base64 encode
    if (c === "ZHRhZG1pbnVzZXI6RHRBZG1pblVzZXJAMjAyNA==") {
      dispatch(isLoggedIn());
      navigate("/auth/");
      Cookies.set("authToken", c, { expires: 0.25 });
    } else {
      message.error("Invalid Credentials");
    }
  };

  return (
    <>
      <Header />
      <div className="LoginScreen-Box">
        <Container>
          <div className="login-main-container">
            {/* <img src={Logo} style={{ width: "50px", marginBottom: "20px" }} /> */}
            <div className="loginHeadTxt">Login and continue</div>
            <div className="loginHeadQuote">
              Digital replicas for efficiency and innovation
            </div>
            <div className="form-container">
              <Form
                onFinish={onFinish}
                className="mt-5"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <Form.Item name={"userName"}>
                  <Input className="customInput" placeholder="Username" />
                </Form.Item>
                <Form.Item name={"password"}>
                  <Input
                    className="customInput"
                    placeholder="Password"
                    type="password"
                  />
                </Form.Item>
                <Form.Item name={"password"}>
                  <Button
                    className="custom-Button"
                    htmlType="submit"
                    type="primary"
                  >
                    Sign in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default LoginScreen;
