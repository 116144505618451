import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Dropdown,
  Button,
  MenuProps,
  Card,
  message,
  Tabs,
  Spin,
  Image,
} from "antd";
import { BsCopy } from "react-icons/bs";
import type { TabsProps } from "antd";

import PageHeader from "../../components/pageHeader";
import SubModalDetails from "./componets/subModalDetails";

import ShareModal from "./componets/shareModal";
import PublishModal from "./componets/publishModal";
import ExportModal from "./componets/exportModal";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../config/api";
import { DELETE, GET } from "../../../utils/apiCall";
import SpecificAssetIdModal from "./componets/specificAssetModal";

function DigitalTwinDetails() {
  const [share, setShare] = useState(false);
  const [publish, setPublish] = useState(false);
  const [exports, setExport] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();
  const [specifiAssetModal, setSpecificAssetModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [subModelsData, setSubModelsData] = useState<any>([]);

  const { id }: any = useParams();

  useEffect(() => {
    setLoading(true);
    fetchData();
    fetchCompleteData();
    setLoading(false);
  }, []);

  const fetchData = async () => {
    try {
      const encodedSearch = id;
      const url = `${API.DIGITALTWIN_URL}${API.GET_TWIN}/${encodedSearch}`;
      const response: any = await GET(url);
      if (response?.id) {
        setDetails(response);
      }
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  const fetchCompleteData = async () => {
    try {
      let query = `?order=DESC&idShort=`;
      const url = `${API.SUBMODAL_URL}${API.GET_SUBMODELS}${query}`;
      const response: any = await GET(url);
      if (response) {
        if (Array.isArray(response?.result)) {
          if (response?.result?.length) {
            setSubModelsData(response?.result);
          } else {
            setSubModelsData([]);
          }
        } else {
          if (response?.id) {
            setSubModelsData([response]);
          } else {
            setSubModelsData([]);
          }
        }
      } else {
        setSubModelsData([]);
      }
    } catch (error: any) {
      setSubModelsData([]);
      console.log(error?.message);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: <div onClick={() => navigate(`/auth/DgTwinForm/${id}`)}>Edit</div>,
      key: "1",
    },
    {
      label: <div onClick={() => deleteDigitalTwin(id)}>Delete</div>,
      key: "2",
    },
  ];

  const Tabsitems: TabsProps["items"] = details?.submodels?.map(
    (item: any, index: number) => {
      const correspondingSubmodel = subModelsData.find(
        (submodel: any) => submodel.id === item.keys?.[0]?.value
      );

      return {
        key: index,
        label: correspondingSubmodel?.idShort || item.keys[0]?.value, 
        children: <SubModalDetails type="details" item={item} />,
      };
    }
  );

  const onChange = (key: string) => {};
  const handleCopy = (id: string) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        message.success("ID copied");
      })
      .catch(() => {
        message.error("Failed to copy ID");
      });
  };

  const deleteDigitalTwin = async (id: any) => {
    try {
      console.log("this is the id",id)
      const decodedId = `/${id}`;
      const response: any = await DELETE(
        API.DIGITALTWIN_URL + API.GET_TWIN + decodedId
      );
      message.success("Successfully deleted");
      navigate(-1);
    } catch (error) {
      message.error("Failed to delete submodel");
      console.log(error);
    }
  };
  return (
    <main>
      <Container fluid>
        <PageHeader
          title={"Ship 5868"}
          breadcum={`Dashboard / Digital Twins / ${details?.idShort}`}
        >
          <div>
            <Dropdown menu={{ items }} placement="bottomLeft">
              <Button style={{ width: 150 }}>Manage</Button>
            </Dropdown>
          </div>
        </PageHeader>
        <Spin spinning={loading}>
          <Card>
            <Row>
              <Col sm={2} xs={12}>
                <div className="digitalTwinScreen-image">
                  <Image
                    src={
                      details?.assetInformation?.defaultThumbnail?.path
                        ? details?.assetInformation?.defaultThumbnail?.path
                        : "https://placehold.co/300x300?text=No+Cover+Image+Selected"
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src =
                        "https://placehold.co/300x300?text=No+Cover+Image+Selected";
                    }}
                    alt="Icon Image"
                    height={150}
                    width={"100%"}
                  />
                </div>
              </Col>
              <Col sm={6} xs={12}>
                <div className="digitalTwinScreen-text4">
                  {details?.idShort}
                </div>
                <div className="digitalTwinScreen-text3">
                  {details?.id} &nbsp;&nbsp;
                  <BsCopy
                    color="green"
                    size={20}
                    onClick={() => handleCopy(details?.id || "")}
                  />
                </div>
                <div className="digitalTwinScreen-text5">
                  {details?.description?.[0]?.text}
                </div>
              </Col>
              <Col sm={1} xs={12}></Col>
              <Col sm={3} xs={12}>
                <div className="digitalTwinScreen-text3">
                  Display Name : {details?.displayName?.[0]?.text}
                </div>
                <div className="digitalTwinScreen-text3">
                  Global Asset ID: {details?.assetInformation?.globalAssetId}
                </div>
                <div className="digitalTwinScreen-text3">
                  Kind: {details?.assetInformation?.assetKind}
                </div>
                <div
                  onClick={() => {
                    setSpecificAssetModal(!specifiAssetModal);
                  }}
                  className="digitalTwinScreen-text3"
                >
                  <a style={{ color: "#2c87f0" }}>Specific Asset IDs</a>
                </div>
              </Col>
            </Row>
          </Card>
          <Tabs defaultActiveKey="1" items={Tabsitems} onChange={onChange} />
        </Spin>
      </Container>
      {share ? (
        <ShareModal open={share} close={() => setShare(!share)} />
      ) : null}
      {publish ? (
        <PublishModal open={publish} close={() => setPublish(!publish)} />
      ) : null}
      {exports ? (
        <ExportModal open={exports} close={() => setExport(!exports)} />
      ) : null}
      {specifiAssetModal && (
        <SpecificAssetIdModal
          data={details?.assetInformation?.specificAssetIds}
          open={specifiAssetModal}
          close={() => setSpecificAssetModal(!specifiAssetModal)}
        />
      )}
    </main>
  );
}

export default DigitalTwinDetails;
